<template>
  <div class="pa-3">
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <h3 class="mt-1 mb-2 accent--text font-weight-bold">電表分析</h3>
          <v-card class="mx-auto frosted-glass" elevation="0">
            <v-tabs
              :value="meterOpt"
              @change="handleTabChange"
              background-color="ems-light-periwinkle"
              color="accent"
              align-with-title
            >
              <v-tabs-slider color="white"></v-tabs-slider>
              <v-tab
                v-for="item in meterTabs"
                active-class="white font-weight-bold"
                :key="item.id"
              >
                {{ item.label }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="meterOpt">
              <v-tab-item v-for="(item, index) in meterTabs" :key="item.id">
                <ChartOptions
                  noTarget
                  :tagId="tagId"
                  :types="item.optionTypes"
                  v-model="meterOption"
                  :datePickerType="
                    index === 1 ? 'perDay' : index === 2 ? 'perMonth' : null
                  "
                />
              </v-tab-item>
            </v-tabs-items>
            <v-card flat :height="meterChartData ? '450px' : '200px'">
              <div style="height: 90%">
                <div class="fill-height">
                  <template v-if="meterOption && meterChartData">
                    <v-progress-linear
                      class="mb-2"
                      v-if="
                        meterOption.list.length > 0 &&
                        meterChartData.dataset.length > 0 &&
                        meterOption.list.length *
                          meterTabs[meterOpt].types.length !==
                          meterChartData.dataset.length
                      "
                      :indeterminate="true"
                    />
                  </template>
                  <Echart
                    :data="meterChartData"
                    :no-data="chartStatus(meterOption, meterChart).status"
                    :loading="meterChart.loading"
                    :noDataText="chartStatus(meterOption, meterChart).text"
                  />
                </div>
              </div>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h3 class="mt-1 mb-2 accent--text font-weight-bold">冷氣分析</h3>
          <v-card class="mx-auto frosted-glass" elevation="0">
            <v-tabs
              v-model="ACOpt"
              background-color="ems-light-periwinkle"
              color="accent"
              align-with-title
            >
              <v-tabs-slider color="white"></v-tabs-slider>
              <v-tab
                v-for="item in ACTabs"
                active-class="white font-weight-bold"
                :key="item.id"
              >
                {{ item.label }}
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="ACOpt">
              <v-tab-item v-for="item in ACTabs" :key="item.id">
                <ChartOptions
                  noTarget
                  :tagId="tagId"
                  :types="item.optionTypes"
                  v-model="ACOption"
                />
              </v-tab-item>
            </v-tabs-items>
            <v-card flat :height="ACChartData ? '450px' : '200px'">
              <div style="height: 90%">
                <div class="fill-height">
                  <Echart
                    :data="ACChartData"
                    :no-data="chartStatus(ACOption, ACChart).status"
                    :loading="ACChart.loading"
                    :noDataText="chartStatus(ACOption, ACChart).text"
                  />
                </div>
              </div>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <h3 class="mt-1 mb-2 accent--text font-weight-bold">環境分析</h3>
          <v-card class="mx-auto frosted-glass" width="100%" elevation="0">
            <v-tabs
              v-model="envOpt"
              background-color="ems-light-periwinkle"
              color="accent"
              align-with-title
            >
              <v-tabs-slider color="white"></v-tabs-slider>
              <v-tab
                v-for="item in envTabs"
                active-class="white font-weight-bold"
                :key="item.id"
              >
                {{ item.label }}
              </v-tab>
            </v-tabs>
            <!-- {{envOption}} -->
            <v-tabs-items v-model="envOpt">
              <v-tab-item v-for="item in envTabs" :key="item.id">
                <ChartOptions
                  noTarget
                  :tagId="tagId"
                  :types="item.optionTypes"
                  v-model="envOption"
                />
              </v-tab-item>
            </v-tabs-items>
            <v-card flat :height="envChartData ? '450px' : '200px'">
              <div style="height: 90%">
                <div class="fill-height">
                  <Echart
                    :loading="envChart.loading"
                    :no-data="chartStatus(envOption, envChart).status"
                    :noDataText="chartStatus(envOption, envChart).text"
                    :data="envChartData"
                  />
                </div>
              </div>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import AC from '@/api/ems/AC'
import Meter from '@/api/ems/Meter'
import Environment from '@/api/Environment'
import Echart from '@/components/Echart'
import ChartOptions from '@/components/ChartOptions'
import makeChart from '@/vendor/chart'
import { mapGetters } from 'vuex'

// import fileDownload from 'js-file-download'

export default {
  components: {
    Echart,
    ChartOptions
  },
  props: {
    tagId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      ClassACData: null,
      ClassMeterData: [],
      meterOpt: 0,
      meterTabs: [
        {
          label: '小時用電量',
          id: 'HOUR_ENERGY',
          types: ['hourEnergy'],
          labels: [],
          optionTypes: ['class', 'group']
        },
        {
          label: '日用電量',
          id: 'DAY_ENERGY',
          types: ['dayEnergy'],
          labels: [],
          optionTypes: ['class', 'group']
        },
        {
          label: '月用電量',
          id: 'MONTH_ENERGY',
          types: ['monthEnergy'],
          labels: [],
          optionTypes: ['class', 'group']
        },
        {
          label: '功率',
          id: 'POWER',
          types: ['power'],
          labels: [],
          optionTypes: ['class', 'group']
        },
        {
          label: '電流',
          id: 'CURRENT',
          types: ['current'],
          labels: ['電流'],
          optionTypes: ['class', 'group']
        },
        {
          label: '電壓',
          id: 'VOLTAGE',
          types: ['voltage'],
          labels: ['電壓'],
          optionTypes: ['class', 'group']
        }
      ],
      ACOpt: 0,
      ACTabs: [
        {
          label: '啟停',
          id: 'STATUS',
          types: ['status'],
          labels: [],
          optionTypes: ['classes']
        },
        {
          label: '運轉模式',
          id: 'MODE',
          types: ['mode'],
          labels: [],
          optionTypes: ['classes']
        },
        {
          label: '設定溫度',
          id: 'TEMPERATURE',
          types: ['temperature'],
          labels: [],
          optionTypes: ['classes']
        }
      ],
      envOpt: 0,
      envTabs: [
        {
          label: '室內溫度',
          id: 'roomTemperature',
          types: ['roomTemperature'],
          labels: [],
          optionTypes: ['classes']
        },
        {
          label: '室外溫度',
          id: 'temperature',
          types: ['temperature'],
          labels: [],
          optionTypes: ['classes']
        },
        {
          label: '室外濕度',
          id: 'humidity',
          types: ['humidity'],
          labels: [],
          optionTypes: ['classes']
        },
        {
          label: '室外風速',
          id: 'windSpeed',
          types: ['windSpeed'],
          labels: [],
          optionTypes: ['classes']
        }
      ],
      meterOption: null,
      ACOption: null,
      envOption: null,
      meterChart: {
        request: {},
        loading: false,
        data: null
      },
      ACChart: {
        request: {},
        loading: false,
        data: null
      },
      envChart: {
        request: {},
        loading: false,
        data: null
      },
      loadingChart: false
    }
  },
  watch: {
    meterOption() {
      if (this.meterOption.timestamp[0] && this.meterOption.timestamp[1]) {
        if (
          this.meterChart.request.from !== this.meterOption.timestamp[0] ||
          this.meterChart.request.to !== this.meterOption.timestamp[1]
        ) {
          this.getMeterHistory()
        }
      }
    },
    ACOption() {
      if (this.ACOption.timestamp[0] && this.ACOption.timestamp[1]) {
        if (
          this.ACChart.request.from !== this.ACOption.timestamp[0] ||
          this.ACChart.request.to !== this.ACOption.timestamp[1]
        ) {
          this.getACHistory()
        }
      }
    },
    envOption() {
      if (this.envOption.timestamp[0] && this.envOption.timestamp[1]) {
        if (
          this.envChart.request.from !== this.envOption.timestamp[0] ||
          this.envChart.request.to !== this.envOption.timestamp[1]
        ) {
          // this.getClassEnvHistory()
          this.getEnvHistory()
        }
      } else {
        this.envChart.data = null
      }
    }
  },
  computed: {
    ...mapGetters({
      pageData: 'page/pageData',
      chartColors: 'config/chartColors'
    }),
    meterChartData() {
      if (!this.meterChart.data) return null
      if (this.meterChart.data.length === 0) return null
      const series = []
      const xAxis = []
      const yAxis = []
      // const color = []
      yAxis.push(makeChart.yAxis(this.meterTabs[this.meterOpt].types[0]))
      xAxis.push(makeChart.xAxis(this.meterTabs[this.meterOpt].types[0]))
      const dataset = []
      this.meterChart.data.forEach((d, index) => {
        this.meterTabs[this.meterOpt].types.forEach((tab, tabIndex) => {
          // color.push(d.color)
          const label = this.meterTabs[this.meterOpt].labels[tabIndex]
          series.push({
            ...makeChart.series(this.meterTabs[this.meterOpt].types[0]),
            name: `${d.name}${label ? '_' + label : ''}`,
            datasetIndex: index
          })
          const key = `${tab}List`
          dataset.push({
            dimensions: ['timestamp', 'value'],
            source: d.data ? d.data[key] || [] : []
          })
        })
      })
      const chartOpt = makeChart.data(this.meterTabs[this.meterOpt].types[0])
      return {
        ...chartOpt,
        // color,
        xAxis,
        yAxis,
        dataset,
        series
      }
    },
    ACChartData() {
      if (!this.ACChart.data) return null
      if (this.ACChart.data.length === 0) return null
      const tab = `${this.ACTabs[this.ACOpt].types[0]}List`
      const series = []
      const xAxis = []
      const yAxis = []
      // const color = []
      const dataset = this.ACChart.data.map((d, index) => {
        // color.push(d.color)
        series.push({
          ...makeChart.series(this.ACTabs[this.ACOpt].types[0]),
          name: d.name,
          datasetIndex: index
        })
        yAxis.push(makeChart.yAxis(this.ACTabs[this.ACOpt].types[0]))
        xAxis.push(makeChart.xAxis(this.ACTabs[this.ACOpt].types[0]))
        return {
          dimensions: ['timestamp', 'value'],
          source: d.data ? d.data[tab] || [] : []
        }
      })
      const chartOpt = makeChart.data(this.ACTabs[this.ACOpt].types[0])
      return {
        ...chartOpt,
        xAxis,
        yAxis,
        // color,
        dataset,
        series
      }
    },
    envChartData() {
      if (!this.envChart.data) return null
      const tab = `${this.envTabs[this.envOpt].types[0]}List`
      const data = this.envChart.data[tab]
      if (!data) return {}
      if (data.length === 0) return null

      const chartOpt = makeChart.data(this.envTabs[this.envOpt].types[0])
      if (tab === 'roomTemperatureList') {
        const dataset = []
        const series = []
        data.forEach((item, index) => {
          dataset.push({
            ...chartOpt,
            dimensions: ['timestamp', 'value'],
            source: item.data
          })
          series.push({
            name: item.name,
            datasetIndex: index,
            type: 'line'
          })
        })
        return {
          ...chartOpt,
          dataset: dataset,
          series: series
        }
      }

      return {
        ...chartOpt,
        dataset: {
          dimensions: ['timestamp', 'value'],
          source: data
        }
      }
    }
  },
  methods: {
    handleTabChange(tabValue) {
      this.meterOption = null
      this.meterChart = {
        request: {},
        loading: false,
        data: null
      }
      this.meterOpt = tabValue
    },
    async getACs() {
      try {
        const { data } = await AC.getClass(this.tagId)
        this.ClassACData = data
      } catch (error) {
        console.log(error)
      }
    },
    async getMeters() {
      this.ClassMeterData = []
      try {
        const { data } = await Meter.getClassMeters(this.tagId)
        this.ClassMeterData = data
      } catch (error) {
        console.error(error)
      }
    },
    chartStatus(params, data) {
      if (!params || !params.timestamp[0] || !params.timestamp[1]) {
        return {
          status: true,
          text: '請選擇時間區間以進行查詢'
        }
      }
      if (!data.data) {
        return {
          status: !data.loading,
          text: '查無資料'
        }
      }
      if (Array.isArray(data.data) && data.data.length === 0) {
        return {
          status: !data.loading,
          text: '查無資料'
        }
      }
      return {
        status: false,
        text: '請選擇時間區間以進行查詢'
      }
    },
    async getMeterHistory() {
      this.meterChart.loading = true
      this.meterChart.request = {
        from: this.meterOption.timestamp[0],
        to: this.meterOption.timestamp[1]
      }
      this.meterChart.data = []
      this.ClassMeterData.forEach(async (meter, index) => {
        try {
          const { data } = await Meter.meterHistory({
            id: meter.id,
            from: this.meterOption.timestamp[0],
            to: this.meterOption.timestamp[1],
            type: this.meterTabs[this.meterOpt].id
          })
          if (data) {
            this.meterChart.data.push({
              color: this.chartColors[index],
              data,
              name: meter.name
            })
          }
        } catch (error) {
          console.error(error)
        } finally {
          this.meterChart.loading = false
        }
      })
    },
    async getACHistory() {
      this.ACChart.loading = true
      this.ACChart.request = {
        from: this.ACOption.timestamp[0],
        to: this.ACOption.timestamp[1]
      }
      this.ACChart.data = []
      this.ClassACData.forEach(async (ac, index) => {
        try {
          const { data } = await AC.getACHistory(ac.id, {
            from: this.ACOption.timestamp[0],
            to: this.ACOption.timestamp[1]
          })
          if (data) {
            this.ACChart.data.push({
              color: this.chartColors[index],
              data,
              name: ac.name
            })
          } else {
            this.ACChart.data.push({
              color: this.chartColors[index],
              data: null,
              name: ac.name
            })
          }
        } catch (error) {
          console.error(error)
          this.ACChart.data.push({
            color: this.chartColors[index],
            data: null,
            name: ac.name
          })
        } finally {
          this.ACChart.loading = false
        }
      })
    },
    async getEnvHistory() {
      this.envChart.loading = true
      this.envChart.request = {
        from: this.envOption.timestamp[0],
        to: this.envOption.timestamp[1]
      }
      const schoolId = this.pageData.parents[this.pageData.level - 1]
      try {
        this.envChart.data = null

        const data = {
          roomTemperatureList: null,
          temperatureList: null,
          humidityList: null,
          windSpeedList: null
        }

        // 班級室內溫度
        const classClimatology = await Environment.getClassClimatology(
          this.tagId,
          this.envChart.request.from,
          this.envChart.request.to
        )
        // 教室多台冷氣
        if (classClimatology.data && classClimatology.data.length > 0) {
          classClimatology.data.forEach((item) => {
            data.roomTemperatureList.push({
              name: item.name,
              data: item.roomTemperatureList
            })
          })
        }

        // 室外溫度
        const temperature = await Environment.getSchoolClimatology(
          schoolId,
          'temperature',
          this.envChart.request.from,
          this.envChart.request.to
        )
        data.temperatureList = temperature.data || null

        // 室外濕度
        const humidity = await Environment.getSchoolClimatology(
          schoolId,
          'humidity',
          this.envChart.request.from,
          this.envChart.request.to
        )
        data.humidityList = humidity.data || null

        // 室外風速
        const windspeed = await Environment.getSchoolClimatology(
          schoolId,
          'windspeed',
          this.envChart.request.from,
          this.envChart.request.to
        )
        data.windSpeedList = windspeed.data
          ? windspeed.data.map((item) => {
              if (item.value === '超過12') {
                item.value = 13
              }
              return item
            })
          : null

        this.envChart.data = data
      } catch (error) {
        console.error(error)
        this.envChart.data = null
      }
      this.envChart.loading = false
    }
  },
  mounted() {
    this.getACs()
    this.getMeters()
  }
}
</script>

<style></style>
